/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply bg-white dark:bg-gray-900;
}

body {
  @apply bg-white dark:bg-gray-900;
}
